























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ButtonLink from '@UI/components/links/ButtonLink.vue'
import { CmsTile, CmsImage } from '@Core/@types/skyway'
import { CMSImageTransformer } from '@Marketing/transformers/image'

interface TileData extends CmsTile {
  type: string
}

@Component({
  components: {
    ButtonLink,
  },
})
export default class Tile extends Vue {
  @Prop({ type: Object, required: true }) data!: TileData

  get tileClassModifier(): String {
    return (
      (this.data.extra.type && this.data.extra.type.replace(/_/g, '-')) || ''
    )
  }

  get hasBackgroundImage(): Boolean {
    return this.data.extra.type === 'text_with_background_tile'
  }

  get backgroundImage(): CmsImage | false {
    return this.data.extra.background_image
      ? CMSImageTransformer(this.data.extra.background_image)
      : false
  }

  get hasImage(): Boolean {
    return this.data.extra.type === 'text_image_tile'
  }

  get isGridLayout(): Boolean {
    return this.hasImage
  }

  get linkStyle(): string {
    return this.data.extra.type === 'text_with_background_tile'
      ? 'primary-alt'
      : 'primary'
  }
}
