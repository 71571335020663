// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.tile--about-us{text-align:center;position:relative;height:100vh}.tile--about-us .tile__text-wrapper--background{z-index:2;position:relative;display:flex;align-items:center;height:100%;max-width:unset}.tile--about-us .tile__text-wrapper--background:before{width:100%;height:100%;content:\"\";position:absolute;background:linear-gradient(0deg,#121212,rgba(220,0,0,.82) 45%,rgba(207,0,0,.82) 55%,#121212)}.tile--about-us .tile__overline{margin-bottom:2.5rem;font-size:1.25rem;line-height:1.5rem}.tile--about-us .tile__content{border-radius:unset;background:unset;height:unset;z-index:3;overflow:visible}.tile--about-us .tile__subheading{text-transform:unset;font-weight:600;margin-top:2.5rem;font-size:1rem;line-height:1.1875rem}@media only screen and (min-width:1024px){.tile--about-us .tile__subheading{font-size:1.25rem;line-height:1.3125rem}}.tile--about-us .tile__button{margin-top:2.5rem}.tile ::v-deep .tile__content--has-background:before{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
