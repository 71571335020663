





































import { Component, Prop } from 'nuxt-property-decorator'
import Tile from '@UI/components/tile/Tile.vue'
import { CMSImageTransformer } from '@Marketing/transformers/image'

@Component
export default class AboutUs extends Tile {
  @Prop({ type: String }) pageTemplate!: string

  get backgroundImage(): CmsImage | false {
    return this.data.extra.background_image
      ? CMSImageTransformer(this.data.extra.background_image)
      : false
  }
}
